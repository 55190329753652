import { createStore } from 'redux'
function counterReducer(state = { display: true }, action) {
  switch (action.type) {
    case 'dark':
      return { display: !state.display }
    default:
      return state
  }
}
let store = createStore(counterReducer)
export default store;