import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  Form,
  ToggleButton,
  Row,
  Col,
  Container,
  ProgressBar,
} from "react-bootstrap";
import { MobileView, isMobile } from "react-device-detect";
import Webcam from "react-webcam";
import * as tus from "tus-js-client";
import axios from "axios";
import pauseBtn from "./Pause button.png";
import recordBtn from "./Record Button.png";
import recordTxt from "./Recording Text.png";
import uploadBtn from "./Upload button.png";
import refreshBtn from "./Refresh button.png";
import endBtn from "./End Button.png";
import uploading from "./Uploading Text.png";
import logo from "./Let_sSync.webp";
// import { Stream } from "@cloudflare/stream-react";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import AWS from "aws-sdk";

// import './App.css';

function App() {
  // const client = new S3Client({});

  // export const main = async () => {
  //   const command = new PutObjectCommand({
  //     Bucket: "test-bucket",
  //     Key: "hello-s3.txt",
  //     Body: "Hello S3!",
  //   });

  //   try {
  //     const response = await client.send(command);
  //     console.log(response);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  // const [videoUrl, setVideoUrl] = useState('https://iframe.videodelivery.net/015f53b6b3a7372033f2263877812a07');
  const [capturing, setCapturing] = useState(false);
  const [videoStatus, setVideoStatus] = useState("");
  const [studentChange, setStudentChange] = useState("");
  const [teacher, setTeacher] = useState("Marianne George");
  const [videoId, setVideoId] = useState("");
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [show, setShow] = useState(false);
  const [radioValue, setRadioValue] = useState("Student");
  const [percentage, setPercentage] = useState("1");
  const [camera, setCamera] = useState("user");
  const [showPercentage, setShowPercentage] = useState(false);

  const [allTeachers, setAllTeachers] = useState([]);

  const size = useWindowSize();

  const radios = [
    { name: "Teacher", value: "Teacher" },
    { name: "Student", value: "Student" },
  ];
  // start video button handling
  const handleStartCaptureClick = React.useCallback(() => {
    if (webcamRef.current.state.hasUserMedia != false) {
      setCapturing(true);
      setVideoStatus("start");
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        // mimeType: "mp4"
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    } else {
      setCapturing(false);
      alert("Please Attache Camera or Allow Video Access");
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );
  // stop video
  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setVideoStatus("stop");
    setRecordedChunks([]);
  }, [mediaRecorderRef, webcamRef, setCapturing]);
  // pause video handling
  const handlePauseCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.pause();
    setCapturing(false);
    setVideoStatus("pause");
  }, [mediaRecorderRef, webcamRef, setCapturing]);
  // Resume video
  const handleResumeCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.resume();
    setCapturing(true);
    setVideoStatus("resume");
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    setVideoStatus("");
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      //create file object from blob
      const myFile = new File([blob], "image_" + new Date(), {
        type: blob.type,
      });
      /*//check file object created successfully or not return true
      console.log(myFile instanceof File);
      console.log(myFile);*/

      // save video to S3
      //var ReactS3Uploader = require('react-s3-uploader');
      AWS.config.update({
        accessKeyId: "AKIA4JK43SM4BJ7OZ7MJ",
        secretAccessKey: "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",
      });
      var s3 = new AWS.S3({ region: "us-west-2" });
      var filename = Date.now();
      var params = {
        Body: myFile,
        Bucket: "review-videos",
        Key: filename + ".mp4",
      };
      s3.putObject(params, function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else {
          console.log(data); // successful response
          saveInDB(
            filename + ".mp4",
            "https://review-videos.s3.us-west-2.amazonaws.com/" +
              filename +
              ".mp4"
          );
        }
      });

      // save the video information in database
      const saveInDB = (id, url) => {
        // debugger
        let data = {
          teacher_name: teacher,
          student_name: studentChange,
          uploader: radioValue,
          video_url: url,
          video_id: id,
        };
        // console.log(data);
        let axiosConfig = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
        };
        axios
          .post(
            "https://backend.letssync.me/cloudflarevideo/index.php/insert-video",
            data,
            { axiosConfig }
          )
          .then(function (response) {
            // console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      // Check if there are any previous uploads to continue.
      // upload.findPreviousUploads().then(function (previousUploads) {
      //   // Found previous uploads so we select the first one.
      //   if (previousUploads.length) {
      //     upload.resumeFromPreviousUpload(previousUploads[0])
      //   }

      //   // Start the upload
      //   upload.start()
      // })
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const handleClose = () => {
    alert("Please Submit Form");
  };
  // change name of student
  const handleNameChange = (event) => {
    setStudentChange(event.target.value);
  };
  // change name of teacher
  const handleChangeTeacher = (e) => {
    // debugger
    setTeacher(e.target.value.trim());
  };
  // const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

  const handleSave = (event) => {
    event.preventDefault();
    if (teacher === "" || studentChange === "") {
      alert("Fill Complete Form");
    } else {
      setShow(false);
    }
  };
  const isLandscape = size.height <= size.width;
  const ratio = isLandscape
    ? size.width / size.height
    : size.height / size.width;

  const handleShow = () => setShow(true);
  // fetch teachers from database
  const getTeachers = () => {
    axios
      .get(
        "https://backend.letssync.me/cloudflarevideo/index.php/get-teachers",
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        setAllTeachers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const listItems = allTeachers.map((teacher, index) => (
    <option value={teacher.name} key={index}>
      {teacher.name}
    </option>
  ));

  /*const videoConstraints = {
    width: 1280,
    height: 720,
    // aspectRatio: ratio,
    facingMode: { exact: "user" }
  };*/

  let startBtnPosition = "45%";
  let btnPosition2 = "35%";
  let btnPosition1 = "55%";
  let buttonSize = {
    width: 110,
    height: 50,
  };
  if (isMobile) {
    btnPosition2 = 50 - size.width / 100 + "%";
    btnPosition1 = size.width / 100 + "%";
    buttonSize.width = 60;
    buttonSize.height = 40;
    startBtnPosition = size.width / 100 + "%";
  }

  const handleChangeCamera = (e) => {
    if (e.target.value === "front") {
      setCamera("user");
    } else {
      setCamera({ exact: "environment" });
    }
    setTeacher(e.target.value.trim());
  };
  console.log(camera);
  useEffect(() => {
    handleShow();
    getTeachers();
  }, []);

  return (
    <Container fluid>
      <Row>
        {/*<Stream controls src={videoIdOrSignedUrl} />*/}
        <a target={"_blank"} href={"https://www.lets-sync.com/"}>
          <img
            src={logo}
            style={{
              position: "absolute",
              top: "20px",
              width: "150px",
              zIndex: 1,
            }}
          />
        </a>
        <Modal
          show={showPercentage}
          onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              <img src={uploading} width={200} height={50} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar animated now={percentage} />
          </Modal.Body>
        </Modal>

        {/*<Webcam style={{padding:'0'}} width={100} height={550} videoConstraints={{videoConstraints}} minScreenshotHeight={720} minScreenshotWidth={1024} muted={true} audio={true} ref={webcamRef}/>*/}
        {/*<Webcam style={{padding:'0'}} forceScreenshotSourceSize="true" videoConstraints={{facingMode: 'user', aspectRatio: ratio}} muted={true} audio={true} ref={webcamRef}/>*/}
        <Webcam
          style={{ padding: "0" }}
          videoConstraints={{ facingMode: camera, aspectRatio: ratio }}
          muted={true}
          audio={true}
          ref={webcamRef}
        />

        {videoStatus === "" || videoStatus === "refresh" ? (
          <div style={{ margin: "auto", textAlign: "center", width: "50%" }}>
            <img
              style={{
                position: "absolute",
                bottom: "20px",
                left: startBtnPosition,
              }}
              onClick={handleStartCaptureClick}
              width={buttonSize.width}
              height={buttonSize.height}
              src={recordBtn}
              alt={"Start"}
            />
          </div>
        ) : null}
        {videoStatus === "start" || videoStatus === "resume" ? (
          <div style={{ margin: "auto", textAlign: "center", width: "50%" }}>
            <img
              style={{ position: "absolute", bottom: "70px", left: "45%" }}
              width={buttonSize.width}
              height={buttonSize.height}
              src={recordTxt}
              alt={"Record"}
            />
            <img
              style={{
                position: "absolute",
                bottom: "20px",
                zIndex: 200,
                left: btnPosition1,
              }}
              onClick={handlePauseCaptureClick}
              width={buttonSize.width}
              height={buttonSize.height}
              src={pauseBtn}
              alt={"Pause"}
            />
            <img
              style={{
                position: "absolute",
                bottom: "20px",
                left: btnPosition2,
              }}
              onClick={handleStopCaptureClick}
              width={buttonSize.width}
              height={buttonSize.height}
              src={endBtn}
              alt={"End"}
            />
          </div>
        ) : null}
        {videoStatus === "pause" ? (
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                position: "absolute",
                bottom: "20px",
                left: startBtnPosition,
              }}
              onClick={handleResumeCaptureClick}
              width={buttonSize.width}
              height={buttonSize.height}
              src={recordBtn}
              alt={"Pause"}
            />
          </div>
        ) : null}

        {videoStatus === "stop" ? (
          <div style={{ margin: "auto", textAlign: "center", width: "50%" }}>
            {recordedChunks.length > 0 && (
              <img
                style={{
                  position: "absolute",
                  bottom: "20px",
                  zIndex: 200,
                  left: btnPosition1,
                }}
                onClick={handleDownload}
                width={buttonSize.width}
                height={buttonSize.height}
                src={uploadBtn}
                alt={"Upload"}
              />
            )}
            <img
              style={{
                position: "absolute",
                bottom: "20px",
                zIndex: 200,
                left: btnPosition2,
              }}
              onClick={handleStartCaptureClick}
              width={buttonSize.width}
              height={buttonSize.height}
              src={recordBtn}
              alt={"Start"}
            />
          </div>
        ) : null}
        <div style={{ margin: "auto", textAlign: "center", display: "inline" }}>
          <img
            style={{ position: "absolute", bottom: "20px", right: "5%" }}
            onClick={() => window.location.reload()}
            width={buttonSize.width}
            height={buttonSize.height}
            src={refreshBtn}
            alt={"Refresh"}
          />
        </div>
        {/*Modal for student and teacher*/}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select User Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSave}>
              <Form.Group as={Row} className="m-3" controlId="formUserDetail">
                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={idx % 2 ? "outline-success" : "outline-info"}
                      name="userType"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formSelectTeacher"
              >
                <Form.Label column sm="4">
                  Teacher's Name:
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    onChange={handleChangeTeacher}
                    defaultValue={"DEFAULT"}
                    name="teacher"
                    aria-label="Default select example"
                  >
                    <option disabled={true}>Yoga teacher.....</option>
                    {listItems}
                  </Form.Select>
                </Col>
              </Form.Group>
              <MobileView>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formSelectCamera"
                >
                  <Form.Label column sm="4">
                    Select Camera:
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      onChange={handleChangeCamera}
                      name="teacher"
                      aria-label="Default select Camera"
                    >
                      <option value={"front"}>Front Camera</option>
                      <option value={"back"}>Back Camera</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </MobileView>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextName"
              >
                <Form.Label column sm="4">
                  Type student's name here:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    onChange={handleNameChange}
                    name="student"
                    aria-required={true}
                    type="text"
                    placeholder="Jimi"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formSubmit">
                <Col className="text-center m-auto w-100" sm="8">
                  <Button className={"btn btn-primary"} type="submit">
                    Submit
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </Row>
    </Container>
  );
}

export default App;

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
