import React, {useEffect,useState} from "react";
import  '../../App.css';
import Header from "./../Header/Header";
import DataTableTest from "../VideoModal/VideoModalTest";
import axios from "axios";
import {Button,ButtonGroup, Modal, Form, ToggleButton, Row, Col, Container, ProgressBar} from "react-bootstrap";

function TestView() {
  const [show, setShow] = useState(false);
  const [Videos, setVideos] = useState([])
  const [student, setStudent] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [showVideos, setShowVideos] = useState([])
  const [appendVideo, setAppendVideo] = useState([])
  const [allStudent, setAllStudent] = useState([])
  const [allTeacher, setAllTeachers] = useState([])
  const [VideoURL, setVideoURL] = useState('https://iframe.videodelivery.net/b178df52e44e17b9f29f7d81648dbaa6?muted=true')
  const [teacher, setTeacher] = useState('')
  const [copy, setCopy] = useState(false)
  const [deleteRow, setDeleteRow] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [offset, setOffset] = useState('0')
  const [selectedRows, setSelectedRows] = useState([])

  const fetchAllVideosFromCloudflare = () =>{
    axios.get('https://api.cloudflare.com/client/v4/accounts/bbf0d1b0274ae162a16da76911a09701/stream?status=ready', {
      headers: { 'Content-Type': 'application/json','X-Auth-Email':'bradschwan1@gmail.com','X-Auth-Key':'3b4db9e913ae7d7c77102e6aea77bf837306c' },
    })
      .then(function (response) {
        if (response.data.success) {
          setVideos(response.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const fetchVideoPlayListFromServer = () =>{
    let axiosConfig = {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS"
    };
    axios.post('https://backend.letssync.me/cloudflarevideo/index.php/get-teacher-play-list',{teacher_name:student,start_date:startDate,end_date:endDate}, {axiosConfig},
    )
      .then(function (response) {
        console.log(response);
        if (response.data.status == '200') {
          setVideos(response.data.result);
          setVideoURL('https://iframe.videodelivery.net/'+response.data.result[0]['video_id']+'?autoplay=true');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const deleteVideosFromServer = (id) =>{
    axios.post('https://backend.letssync.me/cloudflarevideo/index.php/delete-video',{video_id:id}, {
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const deleteVideo = (id) =>{
    axios.delete('https://api.cloudflare.com/client/v4/accounts/bbf0d1b0274ae162a16da76911a09701/stream/'+id,{
      headers: { 'Content-Type': 'application/json','X-Auth-Email':'bradschwan1@gmail.com','X-Auth-Key':'3b4db9e913ae7d7c77102e6aea77bf837306c' },
    })
      .then(function (response) {
        console.log(response)
        deleteVideosFromServer(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getStudent = () => {
    axios.get('https://backend.letssync.me/cloudflarevideo/index.php/get-all-student',{
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        setAllStudent(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getTeachers = () => {
    axios.get('https://backend.letssync.me/cloudflarevideo/index.php/get-teachers',{
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        // debugger
        // console.log(response.data);
        setAllTeachers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let playVideo = (id) => {
    let vURL = 'https://iframe.videodelivery.net/'+id;
    // alert(id);
    setVideoURL(vURL);
    setShowVideo(true);
    window.scrollTo(0, 0)
  }
  let copyToClip = (url) => {
    setCopy(true);
    navigator.clipboard.writeText(url);
  }

  const handleChange = (e) => {
    setTeacher(e.target.value.trim())
    setShowVideo(false);
    setVideoURL('');
  }
  const deleteRecord = () => {
    selectedRows.map((row,i) => {
      deleteVideo(row.video_id)
      let filteredArray = Videos.filter(({ video_id }) => row.video_id !== video_id)
      setVideos(filteredArray);
      setShowVideo(false);
      setVideoURL('');
      setDeleteRow(false);
    })

  }

  const playNext = (id) => {
    // console.log(id);
    let vURL = 'https://iframe.videodelivery.net/'+id+'?autoplay=true';
    // 'https://iframe.videodelivery.net/b178df52e44e17b9f29f7d81648dbaa6
    // alert(id);
    setVideoURL(vURL);
  }
  const handleRowSelected = ({ selectedRows }) => {
    if(selectedRows.length > 0){
      setDeleteRow(true);
    } else {
      setDeleteRow(false);
    }
    setSelectedRows(selectedRows);
  };
  // const listItems = allStudent.map((student) => (<option value={student}>{student}</option>));
  const listItems = allTeacher.map((teacher,index) => (<option value={teacher.name} key={index}>{teacher.name} </option>));
  let videoList
  if(Videos.length!=0) {
     videoList = Videos.map((video, key) => {
      // cloudflare video thumbnail url
      let url = 'https://videodelivery.net/' + video.video_id + '/thumbnails/thumbnail.jpg?time=1s&height=100'
      return (
        <>
          <p className={'py-4'}>{key + 1}.
            <a onClick={() => playNext(video.video_id)} style={{cursor: 'pointer'}}>
              <img className={'mx-3'} src={url} width="100"/>{video.student_name}
            </a>
          </p>
          <hr color="grey"></hr>
        </>
      );
    });
  }else{
    videoList = <>
      <p className={'py-4'}>
        No Videos Found!
      </p>
      <hr color="grey"></hr>
    </>
  }

  let detectEnd = () => {
    console.log('scroll');
    let windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let PAGE_SIZE = 20;
    let windowBottom = windowHeight + window.pageYOffset + 20;
    // if (windowHeight > 1400) {
    //   windowBottom = windowBottom + 200;
    // }
    if (
      windowBottom + 100 >= docHeight && appendVideo.len>0
      // !isEmpty(getOr([], "newEvents", this.state))
      // && this.state.loading === false
    ) {
      // setOffset({ offset+PAGE_SIZE });
      // this.callData("initial");
    }
  }

  const  handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  }
  const  handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  }
  const handleChangeStudent = (e) => {
    setStudent(e.target.value.trim())
  };
  const handleSave = (event) => {
    event.preventDefault();
    if(student ==='' && endDate === '' && startDate === '' ){
      alert('Fill Complete Form');
    }else{
      fetchVideoPlayListFromServer();
      setShow(false)
    }
  }
  const handleClose = () => {
    alert("Please Submit Form")
  };
  /*useEffect(() => {
    fetchAllVideosFromServer();
  }, [teacher]);*/
  const handleShow = () => setShow(true);
  useEffect(() => {
    // getStudent();
    handleShow()
    getTeachers()
    // document.addEventListener("scroll", detectEnd, { passive: true });
    // return () => window.removeEventListener("scroll", detectEnd);
  }, []);

  return (
    <Container fluid>
      <Row>
        <Header/>
        <h4>Play List of {student}</h4>
        <Modal show={show} onHide={handleClose} centered aria-labelledby="contained-modal-title-vcenter" size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Select Yoga Teacher and date of Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSave}>

              <Form.Group as={Row} className="mb-3" controlId="formSelectTeacher">
                <Form.Label column sm="4">
                  Yoga Teacher:
                </Form.Label>
                <Col sm="8">
                  <Form.Select onChange={handleChangeStudent} defaultValue={'DEFAULT'} name='student' aria-label="Default select example">
                    <option disabled={true}>Yoga teacher.....</option>
                    {listItems}
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
                <Form.Label column sm="4">
                  Select teacher's upload start date:
                </Form.Label>
                <Col sm="8">
                  <Form.Control onChange={handleStartDateChange} name='start-date' aria-required={true} type="date"  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
                <Form.Label column sm="4">
                  Select teacher's upload end date:
                </Form.Label>
                <Col sm="8">
                  <Form.Control onChange={handleEndDateChange} name='end-date' aria-required={true} type="date"  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formSubmit">
                <Col className='text-center m-auto w-100' sm="8">
                  <Button  className={'btn btn-primary'} type="submit" >
                    Submit
                  </Button>
                </Col>
              </Form.Group>
            </Form>

          </Modal.Body>
        </Modal>
        <div>
          <div className="Container">
            <div className="row canvas mt-5">
              <div className="col-lg-7 col-md-12 col-sm-12 video">
                <iframe
                  title='Play Video'
                  src={VideoURL}
                  height="500"
                  width='100%'
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                  id="stream-player"
                  allowFullScreen={true}
                />
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 ">
                <section className="list">
                  {videoList}
                </section>
              </div>
            </div>
          </div>
        </div>
        {/*<DataTableTest data={Videos} handleClickId={playVideo} copy={copyToClip} selecting={handleRowSelected} />*/}
      </Row>
    </Container>
  );
}

export default TestView;