import React, {useEffect,useState} from "react";
import Header from "./../Header/Header";
import axios from "axios";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import DataTable from "../VideoModal/VideoModal";

function ShowAllVideos() {
  const [Videos, setVideos] = useState([])
  const [allTeachers, setAllTeachers] = useState([])
  const [VideoURL, setVideoURL] = useState('https://iframe.videodelivery.net/1ab61259b0fbf33f3d5b71f2015fe32d')
  const [teacher, setTeacher] = useState('')
  const [copy, setCopy] = useState(false)
  const [deleteRow, setDeleteRow] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const fetchAllVideosFromCloudflare = () =>{
    axios.get('https://api.cloudflare.com/client/v4/accounts/bbf0d1b0274ae162a16da76911a09701/stream?status=ready', {
      headers: { 'Content-Type': 'application/json','X-Auth-Email':'bradschwan1@gmail.com','X-Auth-Key':'3b4db9e913ae7d7c77102e6aea77bf837306c' },
    })
      .then(function (response) {
        if (response.data.success) {
            setVideos(response.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const fetchAllVideosFromServer = () =>{
    axios.get('https://backend.letssync.me/cloudflarevideo/index.php/get-videos?teacher='+teacher, {
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        setVideos(response.data);
        // console.log(response);
        if (response.data.success) {
            setVideos(response.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const deleteVideosFromServer = (id) =>{
    axios.post('https://backend.letssync.me/cloudflarevideo/index.php/delete-video',{video_id:id}, {
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const deleteVideo = (id) =>{
    axios.delete('https://api.cloudflare.com/client/v4/accounts/bbf0d1b0274ae162a16da76911a09701/stream/'+id,{
      headers: { 'Content-Type': 'application/json','X-Auth-Email':'bradschwan1@gmail.com','X-Auth-Key':'3b4db9e913ae7d7c77102e6aea77bf837306c' },
    })
      .then(function (response) {
        console.log(response)
        deleteVideosFromServer(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getTeachers = () => {
    axios.get('https://backend.letssync.me/cloudflarevideo/index.php/get-teachers',{
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        // debugger
        console.log(response.data);
        setAllTeachers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let playVideo = (id) => {
    let vURL = 'https://iframe.videodelivery.net/'+id;
    // alert(id);
    setVideoURL(vURL);
    setShowVideo(true);
    window.scrollTo(0, 0)
  }
  let copyToClip = (url) => {
    setCopy(true);
    navigator.clipboard.writeText(url);
  }

  const handleChange = (e) => {
      setTeacher(e.target.value.trim())
      setShowVideo(false);
      setVideoURL('');
    }
  const deleteRecord = () => {
    selectedRows.map((row,i) => {
      deleteVideo(row.video_id)
      let filteredArray = Videos.filter(({ video_id }) => row.video_id !== video_id)
      setVideos(filteredArray);
      setShowVideo(false);
      setVideoURL('');
      setDeleteRow(false);
    })

  }

  const handleRowSelected = ({ selectedRows }) => {
    if(selectedRows.length > 0){
      setDeleteRow(true);
    } else {
      setDeleteRow(false);
    }
    setSelectedRows(selectedRows);
  };
  // console.log('allTeachers');
  const listItems = allTeachers.map((teacher) => (<option value={teacher.name}>{teacher.name}</option>));

  useEffect(() => {
    fetchAllVideosFromServer();
  }, [teacher]);

  useEffect(() => {
    getTeachers();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Header/>
        <Form.Group as={Row} className="mb-3" controlId="formSelectTeacher">
          <Col className='sm-6'></Col>
          <Form.Label column sm="2" >
            Select Teacher
          </Form.Label>
          <Col sm="4">
            <Form.Select onChange={handleChange} name='teacher' aria-label="Default select example">
              <option disabled={true} value={''} selected>Yoga teacher.....</option>
              <option value={'all'} >All</option>
              {listItems}
            </Form.Select>
          </Col>
        </Form.Group>

        {/*if video selected show iframe*/}
        {showVideo ?<iframe
          title='Play Video'
          src={VideoURL}
          height="620"
          width='100%'
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          id="stream-player"
        />:null}

        {deleteRow?
          <Row>
            <Col>
              <Button className='mt-3 btn btn-sm' onClick={deleteRecord}>Delete Selected Rows</Button>
            </Col>
          </Row>
          :null}
        <DataTable data={Videos} handleClickId={playVideo} copy={copyToClip} selecting={handleRowSelected} />
      </Row>
    </Container>
  );
}

export default ShowAllVideos;
