import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from './../../App';
import ShowAllVideos from './../ShowAllVideos/ShowAllVideos';
import TeacherPlayList from "../ShowAllVideos/TeacherPlayList";
import StudentPlayList from "../ShowAllVideos/StudentPlayList";
import LeaderPlayList from "../ShowAllVideos/LeaderPlayList";
import TestView from './../TestView/TestView';
import { Redirect } from 'react-router-dom';
import store from "../../Store";

export default function AppRouters(){

  const routes = [
    {
      path:'/',
      component:App,
    },
    {
      path:'/services',
      component:ShowAllVideos,
    },
    {
      path:'/test',
      component:TestView,
    },
  ];
  return(
    <>
      <Router>
        <Switch>

          {/*<Main>*/}
            {/*{
              routes.map(({path,component:C,themeHandler})=>{
                <Route  path={path} component={C}
                        render={(props) => (
                          <C {...props} themeHandler={themeHandler} />
                        )}
                />
                // render={(props) => {< C {...props} themeHandler={themeHandler}} />}
              })
            }*/}
            <Route exact path='/' component={App}/>
          <Route path='/show-all' component={ShowAllVideos}/>
          <Route path='/show-play-list' component={ShowAllVideos}/>
          <Route path='/teacher-playlist' component={TeacherPlayList}/>
          <Route path='/student-playlist' component={StudentPlayList}/>
          <Route path='/leader-playlist' component={LeaderPlayList}/>
          <Route path='/test' component={TestView}/>
          <Route render={() => <Redirect to={{pathname: "/"}} />} />
          {/*<Route path="*" component={App} />*/}
          {/*<Route path='/services' component={Services} test={'abc'} themeColorMethod={changeThemeColor} />*/}
            {/*<Route path='/services' render={(props) => (
              <Services {...props} themeColorMethod={changeThemeColor} />
            )}  />*/}
          {/*</Main>*/}
        </Switch>
      </Router>
    </>
  );
}