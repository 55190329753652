import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import AppRouter from './Components/Routes/Routes';
import './index.css';
import StoreVal from './Store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const routesHandler = (
  <Provider store={StoreVal}>
    <React.StrictMode>
      <AppRouter/>
    </React.StrictMode>
  </Provider>
)

ReactDOM.render(
  routesHandler,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
