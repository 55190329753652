import React from 'react';
import {Button} from 'react-bootstrap';
import DataTable  from 'react-data-table-component';
const VideoModal = (props) => {

  const columns = [
    {
      name: 'Teacher Name',
      selector: row => row.teacher_name,
      sortable: true,
      width: 'auto'
      /*onRowClicked(row, event) =>
      {
        alert('yes');
      },*/
    },
    {
      name: 'Student Name',
      selector: row => row.student_name,
      sortable: true,
    },
    {
      name: 'Recorded By',
      selector: row => row.uploader,
      sortable: true
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true
    },
    {
      name: 'Time',
      selector: row => row.time,
      sortable: true
    },
    {
      name: 'Video Actions',
      cell: (row) => (
        <>
          <Button onClick={()=> window.open(row.video_url, "_blank")} className='btn btn-danger btn-sm m-2'>Play</Button><br/>
          <Button title={row.video_url} onClick={() => props.copy(row.video_url)} className='m-2 btn btn-primary btn-sm'>Copy Link</Button>
        </>
      ),
    },
  ];

  let obj = props.data;
console.log(obj);
  return (
    <DataTable
      title="Video Recorded List"
      columns={columns}
      data={obj}
      // onRowClicked={obj => {props.handleClickId(obj.video_id)}}
      direction="auto"
      fixedHeaderScrollHeight="300px"
      pagination
      responsive
      subHeaderAlign="right"
      onSelectedRowsChange={props.selecting}
      // clearSelectedRows={props.toggledClearRows}
      // highlightOnHover
      // pointerOnHover
      // subHeaderWrap
      selectableRows
    />

  );
}

export default VideoModal;