import React, { useEffect, useState } from "react";
import Header from "./../Header/Header";
import axios from "axios";
import { Button, Modal, Form, Row, Col, Container } from "react-bootstrap";
import Img404 from "./../../404.jpeg";
import "../../App.css";
import { Input, Label, FormGroup } from "reactstrap";
import uploadBtn from "./../../Upload button.png";
import { isBrowser } from 'react-device-detect';
import VideoImageThumbnail from 'react-video-thumbnail-image'; // use npm published version

function TeacherPlayList() {
  const [show, setShow] = useState(false);
  const [Videos, setVideos] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lastDates, setLastDates] = useState(0);
  const [allTeacher, setAllTeachers] = useState([]);
  const [VideoURL, setVideoURL] = useState("");
  const [videoID, setVideoID] = useState("");
  const [teacher, setTeacher] = useState("");
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState([]);
  const [disable, setDisable] = useState(false);
  const [studentVideoList, setStudentVideoList] = useState([]);
  const [onLoadPresent, setOnLoadPresent] = useState(false);
  const [paramPresent, setParamPresent] = useState(false);

  const fetchVideoPlayListFromServer = () => {
    let axiosConfig = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
    };
    axios
      .post(
        "https://backend.letssync.me/cloudflarevideo/index.php/get-teacher-play-list",
        {
          teacher_name: teacher,
          start_date: startDate,
          end_date: endDate,
          last_days: lastDates,
        },
        { axiosConfig }
        // axios.post('http://localhost/cloudflarevideo/index.php/get-teacher-play-list',{teacher_name:teacher,start_date:startDate,end_date:endDate,last_days:lastDates}, {axiosConfig},
      )
      .then(function (response) {
        // console.log(response);
        if (response.data.status == "200") {
          // let dta = response.data.result.filter(item => item.student_name === '12 12' );
          console.log(response.data.result);
          setVideos(response.data.result);
          setStudentVideoList(response.data.result);
          setStudents(response.data.students);
          setVideoID(response.data.result[0]["video_id"]);
          setVideoURL(
            response.data.result[0]["video_url"]
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTeachers = () => {
    axios
      .get(
        "https://backend.letssync.me/cloudflarevideo/index.php/get-teachers",
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        setAllTeachers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const playNext = (url) => {
    // let vURL = "https://iframe.videodelivery.net/" + id + "?autoplay=true";
    setVideoURL(url);
    // setVideoID(id);
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const formatTime = (dateString) => {
    var event = new Date(dateString);

    // console.log(event.toLocaleTimeString('en-US'));
    // const options = { year: "numeric", month: "short", day: "numeric",hour12:true }
    return event.toLocaleTimeString("en-US");
    // return new Date(dateString).toLocaleDateString('en-US')
  };
  const listItems = allTeacher.map((teacher, index) => (
    <option value={teacher.name} key={index}>
      {teacher.name}{" "}
    </option>
  ));
  let videoList;
  if (Videos.length != 0) {
    videoList = studentVideoList.map((video, key) => {
      // cloudflare video thumbnail url
      let url =
        "https://videodelivery.net/" +
        video.video_id +
        "/thumbnails/thumbnail.jpg?time=1s&height=150";
      return (
        <span key={key}>
          <Row
            onClick={() => playNext(video.video_id)}
            style={{ cursor: "pointer" }}
          >
            <Col xs lg="4" className={"d-inline"}>
              <p className={"py-4"}>
                {/*{key + 1}.*/}
                {/*<input type={'checkbox'}/>*/}
                <a
                  onClick={() => playNext(video.video_url)}
                  style={{ cursor: "pointer" }}
                >
                  <VideoImageThumbnail
    videoUrl={video.video_url}
    thumbnailHandler={(thumbnail) => console.log(thumbnail)}
    />
                </a>
              </p>
            </Col>
            <Col>
              <Row className={"d-inline"}>
                <Col sm={"12"} md={"12"} lg={"12"}>
                  <span>
                    {key + 1}. {video.student_name}
                  </span>
                </Col>
                <Col sm={"12"} md={"12"} lg={"12"}>
                  <span>Video Uploaded by: {video.uploader}</span>
                </Col>
                <Col sm={"12"} md={"12"} lg={"12"}>
                  <span>
                    Video Uploaded date: {formatDate(video.created_at)}
                  </span>
                </Col>
                <Col sm={"12"} md={"12"} lg={"12"}>
                  <span>
                    Video Uploaded Time: {formatTime(video.created_at)}
                  </span>
                </Col>
                <Col sm={"12"} md={"12"} lg={"12"}>
                  <span>
                    <button
                      className={"btn text-white btn-success mt-1"}
                      onClick={() => window.open(video.video_url, "_blank")}
                    >
                      Play Video in new tab
                    </button>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr
            className={videoID === video.video_id ? "list-color" : "text-muted"}
          ></hr>
        </span>
      );
    });
  } else {
    videoList = (
      <>
        <p className={"py-4"}>No Videos Found!</p>
        <hr color="grey"></hr>
      </>
    );
  }
  const studentList = students.map((student) => (
    <option value={student}>{student}</option>
  ));
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleChangeTeacher = (e) => {
    setTeacher(e.target.value.trim());
  };
  const handleChangeDays = (e) => {
    // console.log(e.target.value);
    if (e.target.value !== "Select Dates") {
      let dates = [];
      dates["Today"] = 1;
      dates["2 Days"] = 2;
      dates["3 Days"] = 3;
      dates["4 Days"] = 4;
      setLastDates(dates[e.target.value]);
      setDisable(true);
    } else {
      setLastDates(0);
      setDisable(false);
    }
  };
  const handleSave = (event) => {
    event.preventDefault();
    if (teacher === "" && endDate === "" && startDate === "") {
      alert("Fill Complete Form");
    } else {
      fetchVideoPlayListFromServer();
      setShow(false);
    }
  };
  const handleChangeStudent = (e) => {
    // console.log(e.target.value);
    setStudent(e.target.value);
    if (e.target.value === "all") {
      setStudentVideoList(Videos);
    } else {
      let dta = Videos.filter((item) => item.student_name === e.target.value);
      setStudentVideoList(dta);
      setVideoID(dta[0]["video_id"]);
      setVideoURL(
        dta[0]["video_url"]
      );
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    query = decodeURI(query)
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (onLoadPresent == true) {
      fetchVideoPlayListFromServer();
    }
  }, [onLoadPresent]);

  useEffect(() => {
    if (lastDates !== 0) {
      fetchVideoPlayListFromServer();
      setShow(false);
    }
  }, [lastDates]);

  useEffect(() => {
    getTeachers();
    let t = "";
    let sD = "";
    let eD = "";
    if (getQueryVariable("teacher") !== false) {
      t = getQueryVariable("teacher");
      if (t.includes("%20")) {
        t = t.replace("%20", " ");
      }
      setTeacher(t);
    }
    if (getQueryVariable("start_date") !== false) {
      sD = getQueryVariable("start_date");
      setStartDate(sD);
    }
    if (getQueryVariable("end_date") !== false) {
      eD = getQueryVariable("end_date");
      setEndDate(eD);
    }
    if (t === "" && eD === "" && sD === "") {
      handleShow();
    } else {
      setOnLoadPresent(true);
      setShow(false);
      setParamPresent(true);
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Header />
        <Row>
          <Col>
            <h4 className={"d-inline-block d-flex"}>Play List of {teacher}</h4>
            <form className={"d-inline-block"}>
              {paramPresent !== true ?
                <FormGroup>
                  {student != "" ? (
                    <p className={"d-block"}>Selected Student: {student}</p>
                  ) : (
                    <p className={"d-block"}>Selected Student: All</p>
                  )}
                  <Label for="exampleSelectMulti">
                    Pick Student for Play List:
                  </Label>
                  <Input
                    onChange={handleChangeStudent}
                    type="select"
                    name="selectMulti"
                    id="exampleSelectMulti"
                  >
                    <option value={"all"} selected>
                      All
                    </option>
                    {studentList}
                  </Input>
                </FormGroup>
                : null}
            </form>
          </Col>
          {paramPresent !== true ?
            <Col>
              <button
                onClick={handleShow}
                style={{ float: "right" }}
                className={"btn btn-outline-success"}
              >
                Search Videos
              </button>
            </Col>
            : null}
        </Row>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Yoga Teacher and date of Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSave}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formSelectTeacher"
              >
                <Form.Label column sm="4">
                  Yoga Teacher:
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    onChange={handleChangeTeacher}
                    defaultValue={"DEFAULT"}
                    name="teacher"
                    aria-label="Default select example"
                  >
                    <option disabled={true} value={""} selected>
                      Yoga teacher.....
                    </option>
                    {listItems}
                  </Form.Select>
                </Col>
              </Form.Group>
              {/*<Form.Group as={Row} className="mb-3" controlId="formSelectDays">
                <Form.Label column sm="4">
                  Show Videos of last Days:
                </Form.Label>
                <Col sm="8">
                  <Form.Select onChange={handleChangeDays} defaultValue={'DEFAULT'} name='daysSelect' aria-label="Default select example2">
                    <option  value={''} selected>Show Videos of last.....</option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                    <option value={'3'}>3</option>
                    <option value={'4'}>4</option>
                  </Form.Select>
                </Col>
              </Form.Group>*/}
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextName"
              >
                <Form.Label column sm="4">
                  Select teacher's upload start date:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    disabled={disable}
                    onChange={handleStartDateChange}
                    value={startDate}
                    name="start-date"
                    aria-required={true}
                    type="date"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextName"
              >
                <Form.Label column sm="4">
                  Select teacher's upload end date:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    disabled={disable}
                    onChange={handleEndDateChange}
                    value={endDate}
                    name="end-date"
                    aria-required={true}
                    type="date"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formSubmit">
                <Col sm={4}>
                  <Button
                    className={"btn btn-primary float-left"}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Col>
                <Col sm={8}>
                  <input
                    className={"btn btn-success mx-1"}
                    onClick={handleChangeDays}
                    type="button"
                    value={"Today"}
                  />
                  <input
                    className={"btn btn-success mx-1"}
                    onClick={handleChangeDays}
                    type="button"
                    value={"2 Days"}
                  />
                  <input
                    className={"btn btn-success mx-1"}
                    onClick={handleChangeDays}
                    type="button"
                    value={"3 Days"}
                  />
                  <input
                    className={"btn btn-success mx-1"}
                    onClick={handleChangeDays}
                    type="button"
                    value={"4 Days"}
                  />
                  <input
                    className={"btn btn-success mx-1"}
                    onClick={handleChangeDays}
                    type="button"
                    value={"Select Dates"}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
        <div>
          <div className="Container">
            <div className="row canvas mt-5">
              <div className="col-lg-7 col-md-12 col-sm-12 video">
                <Row style={{ position: "relative" }}>
                  {/*<Col><img style={{position:"absolute"}} src={uploadBtn} width={'100'} height={'100'}/></Col>*/}
                  {/*<Col><img style={{position:"absolute",right:'0.5rem',top:'50%'}} src={uploadBtn} width={'100'} height={'100'}/></Col>*/}
                </Row>
                {isBrowser ?
                  <React.Fragment>
                    {Videos.length != 0 ? (
                      <video
                        title="Play Video"
                        src={VideoURL}
                        height="500"
                        width="100%"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;"
                        id="stream-player"
                        allowFullScreen={true}
                        controls
                      />
                    ) : (
                      <img height={"100%"} width={"100%"} src={Img404} />
                    )}
                  </React.Fragment>
                  : null}
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 ">
                <section className="list">{videoList}</section>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default TeacherPlayList;
